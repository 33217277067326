




















































































































































import Vue from 'vue';
import Loader from "@/components/util/Loader.vue";
import UiString from '@/components/uistring/UiString.vue';
import ResponsiveCardList from '@/components/util/ResponsiveCardList.vue';
import ResponsiveCardListEntry from '@/components/util/ResponsiveCardListEntry.vue';
import SkillStubTooltip from '@/components/skill/SkillStubTooltip.vue';
import SkillStubLink from '@/components/skill/SkillStubLink.vue';

import LegacyMonsterProvider from '@/api/MonsterProvider';
import ILegacyMonster, { ILegacyMonsterDifficulty, ILegacyMonsterImmunity } from '@/models/monsters/ILegacyMonster';
import IMonsterSkillEntry from '@/models/monsters/IMonsterSkillEntry';
import { Blows } from '@/models/skills/IStateBlow';

interface IData {
    loading: boolean;
    error: string;
    monster: ILegacyMonster|null;
    skills: IMonsterSkillEntry[];
    skillFilter: string;
}

interface ILegacyMonsterImmunityEx extends ILegacyMonsterImmunity {
    name: string;
}

export default Vue.extend({
    components: {
        Loader,
        UiString,
        ResponsiveCardList,
        ResponsiveCardListEntry,
        SkillStubLink,
    },
    props: {
        monsterSlug: {
            type: String as () => string,
        } 
    },
    data(): IData {
        return {
            loading: false,
            error: '',
            monster: null,
            skills: [],
            skillFilter: '',
        }
    },
    filters: {
        stat(v: string): string {
            const n = Number(v);
            if (isNaN(n)) {
                return v;
            }
            
            return n.toLocaleString(undefined, {
                useGrouping: true,
                maximumFractionDigits: 0,
            });
        }
    },
    computed: {
        monsterId(): number {
            if (this.monsterSlug) {
                const res = Number(this.monsterSlug);
                if (isNaN(res)) {
                    return -1;
                }
                
                return res;
            }
            
            return -1;
        },
        sortedDifficulties(): ILegacyMonsterDifficulty[] {
            if (!this.monster) {
                return [];
            }
            
            return this.monster.difficulties.sort((a, b) => a.difficulty - b.difficulty);
        },
        baseApiUrl(): string {
            const region = this.$store.state.regionCode;
            return `/api/server/${region}`;
            
        },
        skillList(): IMonsterSkillEntry[] {
            if (!this.skills) {
                return [];
            }
            
            if (this.skillFilter) {
                const asNum = Number(this.skillFilter);
                if (!isNaN(asNum)) {
                    return this.skills.filter((s) => s.id === asNum);
                }
            }
            
            return this.skills;
        },
        immunities(): ILegacyMonsterImmunityEx[] {
            if (!this.monster) {
                return [];
            }
            
            return this.monster.immunities.sort((a, b) => a.stateEffectId - b.stateEffectId).map((im) => {
                return {
                    ...im,
                    name: Blows[im.stateEffectId] && Blows[im.stateEffectId].name ? `${Blows[im.stateEffectId].name} (${im.stateEffectId})` : `Unk ${im.stateEffectId}`,
                }
            });
        }
    },
    watch: {
        monsterId() {
            this.load();
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            if (this.monsterId <= 0) {
                this.monster = null;
                this.error = 'Invalid monster ID';
                return;
            }
            
            this.loading = true;
            try {
                this.monster = await LegacyMonsterProvider.getMonster(this.monsterId);
                try {
                    this.skills = await LegacyMonsterProvider.getMonsterSkills(this.monster.skillTableId);
                } catch (skError) {
                    console.error(skError);
                }
            } catch (error) {
                this.error = String(error);
            } finally {
                this.loading = false;
            }
        }
    }
});
